import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Button from 'src/components/atoms/button';

import CarouselArrowButtonStyle from './style';

type CarouselArrowButtonProps = {
  direction: 'left' | 'right';
  onClick?: () => void;
  isFixTop?: boolean;
  arrowSingleRow?: boolean;
  className?: string;
};

const CarouselArrowButton = ({ direction, onClick, isFixTop, className, arrowSingleRow }: CarouselArrowButtonProps) => (
  <CarouselArrowButtonStyle
    isFixTop={isFixTop}
    direction={direction}
    className={className}
    arrowSingleRow={arrowSingleRow}
  >
    <Button className="arrow-button" onClick={onClick} aria-label="circle-button">
      {direction === 'left' ? <KeyboardArrowLeftIcon /> : <KeyboardArrowRightIcon />}
    </Button>
  </CarouselArrowButtonStyle>
);

export default CarouselArrowButton;
