import { useRouter } from 'next/router';

import { Box, Stack, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'next-i18next';
import Carousel, { ResponsiveType } from 'react-multi-carousel';
import NextLink from 'src/components/atoms/link';
import { useBreakpointV2, useWindowSize } from 'src/helpers/use';
import theme from 'src/theme';

import Button from '@components/atoms/button';
import FontAwesomeIcon from '@components/atoms/fontAwesomeIcon';
import CarouselArrowButton from '@components/molecules/learningPathSection/carouselArrowButton';
import LearningPathCard from '@components/molecules/learningPathSection/learningPathCard';
import { LearningPathSectionStyle } from '@components/organisms/sections/learningPathSection/style';
import useLearningPathSection from '@hooks/learningPath/useLearningPathSection';
import { ISection } from '@interfaces/course';

type LearningPathSectionProps = {
  section: ISection;
};

const LearningPathSection = ({ section }: LearningPathSectionProps) => {
  const { t } = useTranslation('common');
  const { width } = useWindowSize();
  const { learningPathSectionItemList } = useLearningPathSection();
  const router = useRouter();
  const responsive: ResponsiveType = {
    largeDesktop: {
      breakpoint: { max: 6000, min: 2000 },
      items: 2,
      slidesToSlide: 1,
    },
    desktop: {
      breakpoint: { max: 1999, min: 1200 },
      items: 2,
      paritialVisibilityGutter: 40,
      slidesToSlide: 2,
    },
    tablet: {
      breakpoint: { max: 1199, min: 768 },
      items: 1.5,
      slidesToSlide: 1,
    },
    smallTablet: {
      breakpoint: { max: 765, min: 500 },
      items: 1.5,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 499, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  if (learningPathSectionItemList.isLoading || !learningPathSectionItemList.data) {
    return <></>;
  }

  return (
    <LearningPathSectionStyle>
      <Box
        component="img"
        src="/static/images/common/learning-path-bg.png"
        alt="Learning Path"
        position="absolute"
        className="learning-path-bg-frame"
      />
      <Stack
        gap={{
          xs: 2,
          md: 3,
        }}
        className="container"
        width="100%"
      >
        <Box display="flex" justifyContent="space-between" pl={1} flexWrap="wrap" alignItems="center">
          <Typography variant={isSmallScreen ? 'h3' : 'h1'} color={theme.palette.primary.contrastText}>
            {section.name}
          </Typography>
          <NextLink href="learning-path">
            <Button
              variant="text"
              sx={{
                color: theme.palette.background.paper,
                gap: 1,
              }}
            >
              {t('home.section.see_all')} <FontAwesomeIcon icon="fa-regular fa-chevron-right" />
            </Button>
          </NextLink>
        </Box>
        <Carousel
          responsive={responsive}
          partialVisible={isSmallScreen}
          draggable={isSmallScreen}
          swipeable={isSmallScreen}
          containerClass="carousel-container"
          itemClass={`carousel-item ${learningPathSectionItemList.data?.length <= 2 && 'width'}`}
          ssr
          deviceType={width! > 1199 ? 'desktop' : 'mobile'}
          customRightArrow={<CarouselArrowButton direction="right" />}
          customLeftArrow={<CarouselArrowButton direction="left" />}
        >
          {learningPathSectionItemList.data ? (
            learningPathSectionItemList.data?.map((learningPath) => (
              <LearningPathCard
                key={learningPath.id}
                learningPathname={learningPath.name}
                imageSrc={learningPath.cardImage}
                onClick={() => router.push(`/learning-path/${learningPath.id}`)}
              />
            ))
          ) : (
            <Box />
          )}
        </Carousel>
      </Stack>
    </LearningPathSectionStyle>
  );
};

export default LearningPathSection;
