import { styled } from '@mui/material/styles';

const CarouselArrowButtonStyle = styled('div', {
  shouldForwardProp: (prop) => prop !== 'direction' && prop !== 'isFixTop' && prop !== 'arrowSingleRow',
})<{ direction: string; isFixTop?: boolean; arrowSingleRow?: boolean }>(
  ({ theme, direction, isFixTop, arrowSingleRow }) => ({
    position: 'absolute',
    minWidth: '60px',
    minHeight: '60px',
    borderRadius: '50%',
    zIndex: 1,
    backgroundColor: 'white',

    button: {
      '& > svg': {
        fontSize: '44px',
      },
    },

    ...(direction === 'right' && {
      right: '-6px',
    }),

    ...(direction === 'left' && {
      left: '-6px',
    }),

    ...(isFixTop && {
      top: 'calc(50% - 15px)',
    }),

    ...(arrowSingleRow && {
      bottom: '16px',
    }),

    '.arrow-button': {
      minWidth: '60px',
      minHeight: '60px',
      boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
      borderRadius: '50%',
      zIndex: 1,
      color: theme.palette.primary.dark,
    },
  }),
);

export default CarouselArrowButtonStyle;
