import { Box, Stack, Typography } from '@mui/material';
import { truncate } from 'lodash';
import { useTranslation } from 'next-i18next';

import { getAssetUrl } from '@helpers/utils';

type LearningPathCardProps = {
  learningPathname: string;
  imageSrc: string;
  onClick: () => void;
};
const LearningPathCard = ({ learningPathname, imageSrc, onClick }: LearningPathCardProps) => {
  const { t } = useTranslation('common');

  return (
    <Box
      display="flex"
      p={2}
      gap={{
        xs: 2,
        md: 4,
      }}
      borderRadius={4}
      bgcolor="background.paper"
      width="100%"
      onClick={onClick}
      sx={{
        cursor: 'pointer',
      }}
      flexDirection={{
        xs: 'column',
        md: 'row',
      }}
    >
      <Box
        minWidth={{
          xs: '100%',
          md: 280,
        }}
        height={170}
        borderRadius={2}
        overflow="hidden"
      >
        <Box
          component="img"
          src={imageSrc ?? getAssetUrl('thumbnail-course-card.jpg')}
          width="100%"
          height="100%"
          sx={{
            objectFit: 'cover',
          }}
        />
      </Box>
      <Box>
        <Stack height="100%" justifyContent="space-between">
          <Typography
            variant="h3"
            height={{
              xs: 60,
              md: 'fit-content',
            }}
          >
            {truncate(learningPathname, {
              length: 100,
            })}
          </Typography>
          <Typography variant="h6" color="primary.main">
            {t('learning_path_section.helper_text')}
          </Typography>
        </Stack>
      </Box>
    </Box>
  );
};

export default LearningPathCard;
