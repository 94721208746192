import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const LearningPathSectionStyle = styled(Box)(
  ({ theme }) => `
  position: relative;

  height: 426px;
  padding: 0 16px;

  background: linear-gradient(92.76deg, #17988D 24.56%, #116962 93.61%);
  display: flex;
  justify-content: center;
  align-items: center;

  .learning-path-bg-frame {
    position: absolute;
    top: -250px;
    left: -150px;
  }

  .react-multi-carousel-list {
    position: unset;
  }

  .container {
    position: relative;
  }
  
  @media (min-width: 2000px) {
    .container {
      max-width: 1762px !important;
    }
  }
  @media (max-width: 1999px) and (min-width: 1701px) {
    .container {
      max-width: 1472px !important;
    }
  }
  ${theme.breakpoints.up('lg')} {
    .container {
      max-width: 1182px;
    }
    .learning-path-bg-frame {
      left: 6%;
    }
  }
  ${theme.breakpoints.down('lg')} {
    .container {
      max-width: 1150px;
    }
  }
  ${theme.breakpoints.down('md')} {
    padding: 0 6px;
    .container {
      max-width: 1150px;
    }
  }
  
  .carousel-item {
    padding: 0 0.5rem 0 0.5rem;
  }

  .carousel-container {
    .next-button {
      position: absolute;
      right: 80px;
      top: 50%;
      transform: translateY(-50%);
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      background-color: white;
      border-radius: 50%;
      cursor: pointer;
    }

    .previous-button {
      position: absolute;
      left: 80px;
      top: 50%;
      transform: translateY(-50%);
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      background-color: white;
      border-radius: 50%;
      cursor: pointer;
    }
  }
`,
);
